import React from 'react';
import ReactDOM from 'react-dom/client';
import jquery from 'jquery';
import toastr from 'toastr';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-image-lightbox/style.css';
import 'toastr/build/toastr.min.css';
import '../assets/css/login.css';
import '../assets/css/inputs.css';
import '../assets/css/btns.css';
import '../assets/css/dropdowns.css';
import '../assets/css/modals.css';
import '../assets/css/tag.css';
import '../assets/css/styles.css';
import '../assets/css/pegafont.css';
import '../assets/css/animation.css';
import '../assets/css/tables.css';
import '../assets/css/markdown.css';
import '../assets/css/solution.css';
import '../assets/css/toast-ui-editor.css';
import './index.css';
import * as Config from './config/config';
import PageRouter from './router';
import * as api from './user_utils';
import { getCookie, fromBinary } from './common';
import './i18n';
import { RouterProvider } from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';
import pureStore, { storePureStoreContext } from './store/pureStore';
import { promiseCheckClientLicense } from "./api/licenseClient"

window.jQuery = jquery;
window.$ = jquery;
window.toastr = toastr;



export const Footer = (props) => {
  return (
    <div>
      Copyright © Designed by Mobile Communications RD Center-Software R&D Div.1&nbsp;&nbsp;
    </div>
  );
}

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initEnv: false,
      initPortalConfig: false,
      initPath: PageRouter,
    };
    this.setup = this.setup.bind(this);
    this.initEnvValue = this.initEnvValue.bind(this);
  }
  setup() {
    $.ajaxSetup({
      xhrFields: {
        withCredentials: true
      },
      beforeSend: (r, settings) => {
        let original_user_info = getCookie("original_user_info");
        if (original_user_info) {
          let username = JSON.parse(fromBinary(atob(original_user_info))).name;
          r.setRequestHeader(
            "Authorization",
            "Bearer " + username
          );
        }
        r.url = settings.url
      },
      statusCode: {
        427: function (x, y, z) {
          promiseCheckClientLicense()
        }
      }
    });

    //record user usages
    $(document).on("click", '.feature-usage', function (event) {
      let feature = $(this).data('feature');

      if (feature) {
        let user_info_data = getCookie("user_info");
        let user_info = {}
        if (user_info_data)
          user_info = JSON.parse(fromBinary(atob(user_info_data)));
        else {
          if (feature.hasOwnProperty('UserName')) {
            user_info["id"] = ""
            user_info["name"] = feature.UserName;
            user_info["officename"] = "";
          }
        }

        if (feature.hasOwnProperty('ProjectToken') && (feature.ProjectToken == "undefined" || feature.ProjectToken == ""))
          feature.ProjectToken = null;
        if (feature.hasOwnProperty('DatasetToken') && (feature.DatasetToken == "undefined" || feature.DatasetToken == ""))
          feature.DatasetToken = null;

        let userusages = {
          UserId: user_info.id,
          UserName: user_info.name,
          OfficeName: user_info.officename,
          Application: feature.Application,
          Category: feature.Category,
          Action: feature.Action,
          ProjectName: feature.ProjectName,
          ProjectToken: feature.ProjectToken,
          DatasetName: feature.DatasetName,
          DatasetToken: feature.DatasetToken,
          Detail: feature.Detail,
          ApplicationName: feature.ApplicationName,
          ApplicationToken: feature.ApplicationToken
        };
        let handlers = {
          success: function (data) {
            console.log("Add UserUsages Success")
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR, textStatus);
            if (jqXHR.status != 409) {
              //toastr.error('[' + jqXHR.status + ' ' + jqXHR.statusText + '] ' + jqXHR.responseText, "Error");
              console.log(jqXHR.status, jqXHR.statusText);
            }
          }
        };
        api.httpSetUserUsages(this, userusages, handlers);
      }
    });

  }
  initEnvValue() {
    $.ajax({
      url: `${Config.ServerAPIPrefix}config`,
      type: 'GET',
      context: this,
      cache: false,
      success: function (data) {
        Config.setConfig(data);
        this.setState({ initEnv: true });
      },
      error: function (jqXHR) {
        console.log(jqXHR.status, jqXHR.statusText);
        toastr.error(jqXHR.statusText, "Error");
      },
    });
  }
  initPortlLocationConfig() {
    $.ajax({
      url: `${Config.ServerAPIPrefix}portalconfig`,
      type: 'GET',
      context: this,
      cache: false,
      success: function (data) {
        Config.setPortalLocationConfig(data);
        this.setState({ initPortalConfig: true });
      },
      error: function (jqXHR) {
        console.log(jqXHR.status, jqXHR.statusText);
        toastr.error(jqXHR.statusText, "Error");
      },
    });
  }
  componentDidMount() {
    this.setup();
    this.initEnvValue();
    this.initPortlLocationConfig();
  }
  render() {
    return (
      <React.Fragment>
        {this.state.initEnv && this.state.initPortalConfig && (
          <RouterProvider router={this.state.initPath} />
        )}
      </React.Fragment>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  <Provider store={pureStore} context={storePureStoreContext}>
    <Provider store={store}>
      <Main />
    </Provider>
  </Provider>,
);
