import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { httpGetUser } from './user_utils';
import * as Config from './config/config';
import {
  getCookie,
  createCookie,
  deleteAllCookie,
  deleteCookie,
  getDefaultLanguage,
  toBinary,
  setAuthCookie,
  checkSSOAuthActive,
  checkLDAPAuthActive,
  withRouter,
} from './common';
import * as api from './user_utils';
import logo from '../assets/images/logo.png';
import { initNewTraningState } from './store/solutionSlice';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { httpVerifyInvitationCode } from './api/platform';
import { checkIsFunctionEnabled } from './utils/common_utils';
import { Forget_Password } from './User/Forget_Password';

function httpGetAuthenticate(context, username, password, domain, handlers) {
  let hostname = window.location.hostname;
  let encode_password = encodeURIComponent(btoa(password));
  let parameters = `?user=${username}&password=${encode_password}&domain=${domain}&hostname=${hostname}`;
  let settings = {
    url: Config.ServerAPIPrefix + 'ldap/login' + parameters,
    type: 'GET',
    context: context,
    cache: false,
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
}

export function ToLogout() {
  var parameters = '?user=' + atob(getCookie('user'));
  $.get(Config.ServerAPIPrefix + 'ldap/logout' + parameters, (res) => {
    console.log(res);
  });
  deleteAllCookie();
  window.location.href = '/login';
}

export const LDAPLogout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const parameters = '?user=' + atob(getCookie('user'));
    $.get(Config.ServerAPIPrefix + 'ldap/logout' + parameters, (res) => {
      console.log(res);

      deleteAllCookie();
      dispatch(initNewTraningState('all'));
      window.location.href = '/login';
    });
  });
};

const Page_State = {
  login: 'login',
  forget_password: 'forget_password',
};

class LDAPLoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page_state: Page_State.login,
      is_loading: false,

      isAuthed: getCookie('AuthData') ? true : false,
      username: '',
      password: '',
      invitationCode: '',
      domain: '',
      domainList: {},
      lastLoginError: '',
      isLoging: false,
    };
    this.checkUserPermissionByName = this.checkUserPermissionByName.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleInvitaionCode = this.handleInvitaionCode.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleKeyEvent = this.handleKeyEvent.bind(this);
    this.onUserLogin = this.onUserLogin.bind(this);
    this.getLanguage = this.getLanguage.bind(this);
    this.authUserLDAPInfo = this.authUserLDAPInfo.bind(this);
    this.verifyInvitationCode = this.verifyInvitationCode.bind(this);
    if (!this.state.isAuthed) {
      deleteCookie('user_info');
      deleteCookie('original_user_info');
    }
  }
  componentDidMount() {
    const byPassSSO = getCookie('byPass_SSO');
    const errorMessage = getCookie('error_message');
    if (!this.state.isAuthed && checkSSOAuthActive() && !byPassSSO) {
      const { search, pathname } = this.props.location;
      setAuthCookie(search, pathname);
    }
    if (byPassSSO && errorMessage) {
      this.setState({ lastLoginError: errorMessage }, () => {
        deleteCookie('byPass_SSO');
        deleteCookie('error_message');
      });
    }
    const domainList = {
      ...(window?.PORTAL_AUTHSETTING?.LDAP?.Active &&
        checkIsFunctionEnabled('ENABLE_BACKEND_AUTH') && { OA: 'OA' }),
      Standard: 'Standard',
    };
    this.setState({ domainList, domain: Object.keys(domainList)[0] });
  }
  verifyInvitationCode(user) {
    if (!this.state.invitationCode) return;
    httpVerifyInvitationCode(this.state.invitationCode)
      .then((response) => {
        console.log(response);
        if (!response.verified) {
          this.setState({
            lastLoginError: response.message,
            isLoging: false,
          });
        } else {
          this.autoRegisterUser(user);
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({
          lastLoginError: reason,
          isLoging: false,
        });
      });
  }
  autoRegisterUser(user) {
    const { t } = this.props;
    const handlers = {
      success: function (data) {
        this.checkUserPermissionByName();
      },
      error: function () {
        this.setState({
          lastLoginError: 'User auto register fail',
          isLoging: false,
        });
      },
      statusCode: {
        409: function () {
          this.setState({ errMsg: t('message.username_exist') });
        },
      },
    };
    api.httpCreateUser(this, user, handlers);
  }
  authUserLDAPInfo() {
    const handlers = {
      success: function (data) {
        console.log('LDAP:', data);

        if (!data) {
          this.setState({
            lastLoginError: 'LDAP User not found',
            isLoging: false,
          });
          return;
        }

        if (!this.state.invitationCode) {
          this.setState({
            lastLoginError:
              "You're not in our platform, please enter the invitation code",
            isLoging: false,
          });
        } else {
          const user = {
            DisplayName: data[0].name,
            Name: data[0].username,
            Role: 'Guest',
            Email: {
              Address: data[0].mail,
              Verified: false,
            },
            Active: true,
            OfficeName: data[0].physicalDeliveryOfficeName,
            Department: data[0].department,
            Ldap: true,
          };
          this.verifyInvitationCode(user);
        }
      },
      error: function (jqXHR) {
        console.log(jqXHR);
        this.setState({
          lastLoginError: 'LDAP User not found',
          isLoging: false,
        });
      },
    };
    console.log('Searching LDAP Account...');
    api.httpSearchLdapUserWithoutDomain(this, this.state.username, handlers);
  }
  onUserLogin() {
    let username = this.state.username;
    let password = this.state.password;
    const domain = this.state.domain;
    if (username === '' || password === '') {
      this.setState({
        lastLoginError: 'Username or Password should not be empty',
      });
      return;
    }
    if (domain === 'OA' && !checkLDAPAuthActive()) {
      this.setState({ lastLoginError: 'LDAP is not active' });
      return;
    }
    this.setState({ isLoging: true });
    this.checkUserPermissionByName();
  }
  checkUserPermissionByName() {
    var handlers = {
      success: function (res) {
        //console.log(res)
        if (res.Active == false)
          this.setState({
            lastLoginError: 'Your account is not active',
            isLoging: false,
          });
        else {
          var user_info = {
            id: res._id,
            name: res.Name,
            officename: res.OfficeName,
            email: res.Email.Address,
            domain: res.Domain,
            role: res.Role,
            online: res.Online,
            active: res.Active,
            department: res.Department,
            ldap: res.Ldap,
          };
          createCookie('user_info', btoa(toBinary(JSON.stringify(user_info))));
          createCookie(
            'original_user_info',
            btoa(toBinary(JSON.stringify(user_info))),
          );
          this.authenticate();
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(
          jqXHR.status,
          jqXHR.statusText,
          jqXHR.responseJSON['error'],
        );
        if (this.state.domain === 'OA') {
          this.authUserLDAPInfo();
        } else {
          this.setState({
            lastLoginError: 'User not found',
            isLoging: false,
          });
        }
      },
    };

    httpGetUser(this, this.state.username, handlers);
  }
  authenticate() {
    const { username, password, domain } = this.state;
    const context = this;
    var handlers = {
      success: function (res) {
        console.log(res);
        createCookie('user', btoa(username));
        if (res.redirecturl && res.redirecturl != '') {
          var redirecturl = 'https://' + res.redirecturl;

          if (res.redirecturl.includes('://')) redirecturl = res.redirecturl;

          this.getLanguage(username, redirecturl);
        } else {
          context.setState({
            lastLoginError: '',
            isAuthed: true,
            isLoging: false,
          });
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log('Error: ', jqXHR.responseJSON['error']);
        console.log('Detail: ', jqXHR.responseJSON['detail']);

        let message = jqXHR.responseJSON['detail'];
        if (message.includes('Incorrect user scope'))
          message = `User not found in ${domain} users`;
        else if (message.includes('password is incorrect'))
          message = 'Password is incorrect';

        context.setState({
          lastLoginError: message,
          isLoging: false,
        });
      },
    };

    if (domain === 'Standard') {
      const payload = {
        user: username,
        password: password,
        hostname: window.location.hostname,
      };
      api.httpGetStandardUser(this, payload, handlers);
    } else {
      httpGetAuthenticate(this, username, password, domain, handlers);
    }
  }
  handleUsername(event) {
    // console.log(event.target.value.toLowerCase());
    this.setState({ username: event.target.value.toLowerCase() });
  }
  handlePassword(event) {
    this.setState({ password: event.target.value });
  }
  handleInvitaionCode(event) {
    this.setState({ invitationCode: event.target.value });
  }
  handleOptionChange(name, value) {
    this.setState({
      [name]: value,
    });
  }
  handleKeyEvent(event) {
    if (event.which == 13) {
      this.onUserLogin();
    }
  }
  getLanguage(username, redirecturl) {
    let handlers = {
      success: function (data) {
        const lang = data && data.length > 0 ? data : window.language;
        window.language = lang;
        createCookie('language', btoa(lang));
        this.setState({ isLoging: false });
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR, textStatus);
        const lan = getDefaultLanguage();
        createCookie('language', btoa(lan));
        this.setState({ isLoging: false });
      },
      complete: function (jqXHR, textStatus) {
        deleteCookie('RedirectURL');
        this.setState({ isLoging: false });
        location.href = redirecturl;
      },
    };
    api.httpGetUserLanguage(this, username, handlers);
  }
  render() {
    if (this.state.isAuthed) {
      const homepageUrl = window.HOMEPAGE || '/';
      return <Navigate to={homepageUrl} />;
    }
    return (
      <div className='login__background'>
        <div className='width80 col-centered'>
          <img
            style={{ marginTop: '30px', height: '55px', width: 'auto' }}
            src={logo}
          />
          <div id='welcome-to-cambrian' style={{ marginTop: '15%' }}>
            WELCOME TO
            <br />
            PEGA<span style={{ fontWeight: '100' }}>Ai</span>
          </div>
          <div className='login--gold--text' style={{ marginTop: '20px' }}>
            {this.state.lastLoginError}
          </div>

          {this.state.page_state == Page_State.login && (
            <div style={{ maxWidth: '326px' }}>
              <form>
                <input
                  type='text'
                  autoFocus
                  className='form-control input--black_opacity login__user--input'
                  value={this.state.username}
                  placeholder='Username'
                  onChange={this.handleUsername}
                  onKeyPress={this.handleKeyEvent}
                />
                <input
                  type='password'
                  className='form-control input--black_opacity login__user--input'
                  value={this.state.password}
                  placeholder='Password'
                  onChange={this.handlePassword}
                  onKeyPress={this.handleKeyEvent}
                />
                {this.state.domain == 'Standard' && (
                  <div className='flex justify-end cursor-pointer'>
                    <span
                      style={{
                        color: 'white',
                        textDecoration: 'underline',
                        textDecorationColor: 'white',
                      }}
                      onClick={() => {
                        this.setState({
                          page_state: Page_State.forget_password,
                        });
                        this.setState({ lastLoginError: '' });
                      }}
                    >
                      Forget Password?
                    </span>
                  </div>
                )}
                {checkIsFunctionEnabled('ENABLE_INVITATION_CODE') && (
                  <input
                    type='text'
                    className='form-control input--black_opacity login__user--input'
                    value={this.state.invitationCode}
                    placeholder='Invitation Code'
                    onChange={this.handleInvitaionCode}
                    onKeyPress={this.handleKeyEvent}
                  />
                )}
                {checkIsFunctionEnabled('ENABLE_BACKEND_AUTH') && (
                  <div
                    className='form-group'
                    style={{ width: '100%', fontSize: '14px' }}
                  >
                    <DropdownButton
                      id='login_domain_input'
                      className='DpBtn100 select--black_opacity login__user--input'
                      title={this.state.domainList[this.state.domain] || ''}
                      key={this.state.domain}
                      onSelect={(e) => this.handleOptionChange('domain', e)}
                    >
                      <Dropdown.Item
                        eventKey='Domain_Label'
                        key='label_domain'
                        disabled={true}
                      >
                        Domain
                      </Dropdown.Item>
                      {Object.keys(this.state.domainList).map((key, idx) => (
                        <Dropdown.Item eventKey={key} key={key}>
                          {this.state.domainList[key]}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                )}
              </form>
              <div
                className={`flex ${this.state.domain == 'OA' ? 'justify-between' : 'justify-end'}`}
              >
                {this.state.domain == 'OA' && (
                  <a
                    className='btn btn-md btn--login feature-usage'
                    style={{ marginTop: '13px', float: 'right' }}
                    onClick={() => {
                      window.location.href = './signup';
                    }}
                  >
                    <span>SIGN UP</span>
                  </a>
                )}
                <a
                  className='btn btn-md btn--login feature-usage'
                  style={{ marginTop: '13px', float: 'right' }}
                  data-feature={
                    '{"Application":"Cambrian", "UserName":"' +
                    this.state.username +
                    '", "Category":"Login", "Action":"Login"}'
                  }
                  onClick={this.onUserLogin}
                >
                  {this.state.isLoging ? (
                    <span>
                      <FontAwesomeIcon icon={solid('spinner')} spin />
                      &nbsp; LOGINING{' '}
                    </span>
                  ) : (
                    <span>LOGIN</span>
                  )}
                </a>
              </div>

              <br />
            </div>
          )}

          {this.state.page_state == Page_State.forget_password && (
            <Forget_Password
              on_back_login={() =>
                this.setState({ page_state: Page_State.login })
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export const LDAPLogin = withRouter(LDAPLoginPage);
