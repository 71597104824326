import { ServerAPIPrefix } from '../config/config';
import { getDefaultOptions, returnResponse } from './apiConfig';

const perfApiPrefix = `${ServerAPIPrefix}perfProxy/`;
const perfV2ApiPrefix = `${ServerAPIPrefix}perfV2Proxy/`;
const rejudgeApiPrefix = `${ServerAPIPrefix}rejudgeProxy/`;
const jobApiPrefix = `${ServerAPIPrefix}jobProxy/`;
const aoiApiPrefix = `${ServerAPIPrefix}aoiProxy/`;

export const httpPostRejudgeBySolutionsV1 = async (payload) => {
  const options = getDefaultOptions();
  const url = `${perfApiPrefix}PerfTracking/GetRejudgebySolutions`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return returnResponse(response);
};

export const httpPostRejudgeBySolutions = async (payload) => {
  const options = getDefaultOptions();
  const url = `${perfV2ApiPrefix}PerfTracking/GetRejudgebySolutions`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return returnResponse(response);
};

export const httpGetAiResultSummary = async (payload) => {
  const { token, start_time, end_time, data_type } = payload;
  const options = getDefaultOptions();
  const url = `${perfV2ApiPrefix}PerfTracking/GetAiResultSummary?token=${token}&start_time=${start_time}&end_time=${end_time}&data_type=${data_type}`;
  const response = await fetch(url, { ...options });

  return returnResponse(response);
};

export const httpPostRejudgeToGetModelToken = async (payload) => {
  const options = getDefaultOptions();
  const url = `${rejudgeApiPrefix}get_rejudge_data`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return returnResponse(response);
};

// export const httpGetSolutionInfoByProjectToken = async (projectToken) => {
//   const options = getDefaultOptions()
//   const url = `${ServerAPIPrefix}solutionProxy/solution/project_token?token=` + projectToken;
//   const response = await fetch(url, { ...options });
//   return returnResponse(response);
// };

export const httpGetModelTokenBySolutionToken = async (projectToken) => {
  const options = getDefaultOptions();
  const url =
    `${ServerAPIPrefix}solutionProxy/solution/projects_by_solution?token=` +
    projectToken;
  const response = await fetch(url, { ...options });
  return returnResponse(response);
};

export const httpPostFaImage = async (payload) => {
  const options = getDefaultOptions();
  const url = `${rejudgeApiPrefix}get_fa_table_data`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return returnResponse(response, true);
};

export const httpSetThreshold = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}smt_rejudge/config`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return returnResponse(response, true);
};

export const httpGetRejudgeFaDataV1 = async (payload) => {
  const options = getDefaultOptions();
  const url = `${perfApiPrefix}PerfTracking/GetRejudgeFAdata`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return returnResponse(response, true);
};

export const httpGetRejudgeFaData = async ({ payload, signal }) => {
  const options = getDefaultOptions();
  const url = `${perfV2ApiPrefix}PerfTracking/GetRejudgeFAdata`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    signal,
    body: JSON.stringify(payload),
  });

  return returnResponse(response, true);
};

export const httpPutUpdatebyToken = async (payload) => {
  const options = getDefaultOptions();
  const url = `${perfApiPrefix}InferenceData/UpdatebyToken`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  return returnResponse(response, true);
};

export const httpGetAllSolutionList = async () => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}solutions?_sellable=All`;
  const response = await fetch(url, { ...options });
  return returnResponse(response);
};

export const httpPostJobHistory = async (payload) => {
  const options = getDefaultOptions();
  const url = `${jobApiPrefix}job/get_job_history`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return returnResponse(response, true);
};

export const httpCreateSolutionJob = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}solutionProxy/solution_job`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return returnResponse(response, true);
};

export const httpGetJobParameter = async (solutionName, submitter, site) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}solutionProxy/solution/job?solution=${solutionName}&site=${site}&submitter=${submitter}`;
  const response = await fetch(url, { ...options });
  return returnResponse(response);
};

export const httpUpdateJobParameter = async (payload) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}solutionProxy/solution/job`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(payload),
  });
  return returnResponse(response, true);
};

export const httpPostSecondRejudge = async (payload) => {
  const options = getDefaultOptions();
  const url = `${perfApiPrefix}PerfTracking/UpdateSecondRejudge`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return returnResponse(response);
};

export const httpUpdateStationActivation = async (payload) => {
  const options = getDefaultOptions();
  const url = `${aoiApiPrefix}aoi_status/activation`;
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return returnResponse(response, true);
};

export const httpGetCodSummaries = async ({
  begin_time,
  end_time,
  lot,
  status,
  product,
  signal,
}) => {
  const options = getDefaultOptions();
  let url = `${ServerAPIPrefix}inspectProxy/lots/cod_summaries?begin_time=${begin_time}&end_time=${end_time}`;

  if (status !== '' && status !== '---') {
    url += `&status=${status}`;
  }

  if (product) {
    for (let i = 0; i < product.length; i++) {
      url += `&product=${product[i]}`;
    }
  }

  if (lot) {
    for (let i = 0; i < lot.length; i++) {
      url += `&lot=${lot[i]}`;
    }
  }

  const response = await fetch(url, { ...options, signal });
  return returnResponse(response);
};

export const httpGetStation = async () => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}inspectProxy/stations`;
  const response = await fetch(url, { ...options });

  return returnResponse(response);
};

export const httpPutStation = async ({ station, is_enabled }) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}inspectProxy/stations/${station}`;
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify({ is_enabled }),
  });
  return returnResponse(response, true);
};

// export const httpGetAoiStationStatus = async (payload) => {
//   const options = getDefaultOptions();
//   const url = `${aoiApiPrefix}aoi_status`;
//   const response = await fetch(url, {
//     ...options,
//     method: "POST",
//     body: JSON.stringify(payload)
//   });
//   return returnResponse(response, true);
// };

export const httpGetCollections = async () => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}inspectProxy/collections`;
  const response = await fetch(url, { ...options });
  return returnResponse(response);
};

export const httpGetCollectionGroups = async ({ collection_id }) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}inspectProxy/collections/${collection_id}/groups`;
  const response = await fetch(url, { ...options });
  return returnResponse(response);
};

export const httpPatchCollectionGroups = async ({
  collection_id,
  group_id,
  editData,
}) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}inspectProxy/collections/${collection_id}/groups/${group_id}`;
  const response = await fetch(url, {
    ...options,
    method: 'PATCH',
    body: JSON.stringify(editData),
  });
  return returnResponse(response, true);
};

export const httpGetSummaryInfoByTime = async ({
  project_token,
  start_time,
  end_time,
  product_names,
  lots,
  data_type,
  signal,
}) => {
  const options = getDefaultOptions();
  const productNamesQuery = product_names
    .map((name) => `product_names=${encodeURIComponent(name)}`)
    .join('&');
  const lotsQuery = lots
    .map((name) => `lots=${encodeURIComponent(name)}`)
    .join('&');

  let url = `${perfApiPrefix}PerfTracking/SummaryinfobyTime?token=${project_token}&start_time=${start_time}&end_time=${end_time}&data_type=${data_type}&${productNamesQuery}&${lotsQuery}`;
  const response = await fetch(url, { ...options, signal });
  return returnResponse(response);
};

export const httpGetProducts = async ({ begin_time, end_time }) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}inspectProxy/products?begin_time=${begin_time}&end_time=${end_time}`;
  const response = await fetch(url, { ...options });
  return returnResponse(response);
};

export const httpGetAIFilterModes = async () => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}inspectProxy/ai_filter_modes`;
  const response = await fetch(url, { ...options });
  return returnResponse(response);
};

export const httpPatchAIFilterModes = async ({ ai_filter_mode_id }) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}inspectProxy/ai_filter_modes/${ai_filter_mode_id}`;
  const response = await fetch(url, {
    ...options,
    method: 'PATCH',
    body: JSON.stringify({
      active_mode: true,
    }),
  });
  return returnResponse(response, true);
};

export const httpGetCvatFAPreviewURL = (token, data, handlers) => {
  const settings = {
    url: `${ServerAPIPrefix}performance/${token}/fa_to_cvat`,
    type: 'POST',
    contentType: 'application/json',
    processData: false,
    data: JSON.stringify(data),
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
};

export const httpDeleteCvatFAData = (token, taskid, labelId, handlers) => {
  const settings = {
    url: `${ServerAPIPrefix}models/${token}/tasks/${taskid}/fa_to_cvat?_id=${labelId}`,
    type: 'DELETE',
  };
  Object.assign(settings, handlers);
  $.ajax(settings);
};

export const httpTriggerTask = async (
  owner,
  token,
  version,
  modelName,
  from,
  payload,
) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}models/${token}/tasks?_version=${version}&_submodel=${modelName}&_owner=${owner}&_from=${from}`;

  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(payload),
  });
  return returnResponse(response, true);
};

export const httpDeleteCollectionGroups = async ({
  collection_id,
  group_id,
}) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}inspectProxy/collections/${collection_id}/groups/${group_id}`;

  const response = await fetch(url, {
    ...options,
    method: 'Delete',
  });

  return returnResponse(response, true);
};

export const httpPostCollectionGroups = async ({ collection_id, addData }) => {
  const options = getDefaultOptions();
  const url = `${ServerAPIPrefix}inspectProxy/collections/${collection_id}/groups`;

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(addData),
  });
  return returnResponse(response, true);
};
